import { ChangeEvent, PropsWithChildren, useCallback } from "react";
import { Signal } from "@preact/signals-react";

import { SelectField } from "evergreen-ui";

export default function SettingSelect<T extends string>({
  setting,
  values,
  label,
}: {
  setting: Signal<T>;
  label: string;
  values: T[];
}) {
  const onChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      setting.value = e.target.value as T;
    },
    [setting]
  );
  return (
    <SelectField label={label} value={setting.value} onChange={onChange}>
      {values.map((value) => (
        <option key={value} value={value}>
          {value}
        </option>
      ))}
    </SelectField>
  );
}
