const { SSE } = require("sse.js");

import { Signal, signal } from "@preact/signals-react";
import { generateFilename } from "./app";

export async function sendToWhisper({
  blob,
  apiKey,
  whisperPrompt,
}: {
  blob: Blob;
  apiKey: string;
  whisperPrompt: string;
}) {
  const formData = new FormData();
  formData.append("model", "whisper-1");
  formData.append("file", blob, generateFilename(blob));

  if (whisperPrompt) {
    formData.append("prompt", whisperPrompt);
  }

  const response = await fetch(
    "https://api.openai.com/v1/audio/transcriptions",
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
      body: formData,
    }
  );

  return response.json();
}

export interface ChatMessage {
  role: "system" | "user" | "assistant";
  content: string;
}

export interface StreamedResponse {
  state: "waiting" | "running" | "complete";
  model: string;
  prompt: string;
  text: string;
  value: string;
  timings?: [number, number][];
}

export function sendToChatGpt({
  apiKey,
  model,
  systemPrompt,
  text,
  history,
  onCompletion,
}: {
  apiKey: string;
  model: string;
  systemPrompt: string;
  text: string;
  history: ChatMessage[];
  onCompletion?: (value: string) => void;
}): Signal<StreamedResponse> {
  let startTime = Date.now();
  const timings: StreamedResponse["timings"] = [];
  const result = signal<StreamedResponse>({
    state: "waiting",
    model: model,
    prompt: systemPrompt,
    text,
    value: "",
    timings,
  });

  const sse = new SSE("https://api.openai.com/v1/chat/completions", {
    method: "post",
    headers: {
      Authorization: `Bearer ${apiKey}`,
      "Content-Type": "application/json",
    },
    payload: JSON.stringify({
      model: model,
      messages: [
        { role: "system", content: systemPrompt },
        ...history,
        { role: "user", content: text },
      ],
      stream: true,
    }),
  });

  let state = "start";
  let value = "";

  sse.addEventListener("message", (e: { data: string }) => {
    if (state === "done") {
      return;
    }
    try {
      const message = JSON.parse(e.data);
      const choice = message.choices[0];
      if (choice.finish_reason === "stop") {
        state = "done";
        result.value = { ...result.value, state: "complete" };
        if (onCompletion) {
          onCompletion(value);
        }

        return;
      }
      if (message.choices[0].delta.content) {
        value += message.choices[0].delta.content;
        result.value = { ...result.value, state: "running", value };
      }
      timings.push([Date.now() - startTime, value.length]);
    } catch (e) {
      // sse hates it if we throw an error
      console.error(e);
    }
  });

  sse.stream();

  return result;
}

export interface ImageResponse {
  created: number;
  data: [
    {
      url: string;
    }
  ];
}

export async function sendToDallE({
  apiKey,
  text,
}: {
  apiKey: string;
  text: string;
}): Promise<ImageResponse> {
  const data = await fetch("https://api.openai.com/v1/images/generations", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${apiKey}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      prompt: text,
      size: "256x256",
    }),
  });

  return await data.json();
}
