import { ChangeEvent, PropsWithChildren, useCallback } from "react";
import { Signal } from "@preact/signals-react";

import { Checkbox } from "evergreen-ui";

export default function SettingCheckbox({
  setting,
  label,
}: {
  setting: Signal<boolean>;
  label: string;
}) {
  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setting.value = e.target.checked;
    },
    [setting]
  );
  return (
    <Checkbox
      checked={setting.value}
      onChange={onChange}
      label={label}
    ></Checkbox>
  );
}
