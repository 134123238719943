import {
  MouseEvent,
  TouchEvent,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from "react";
import { createRoot } from "react-dom/client";
import { signal, Signal, useSignal } from "@preact/signals-react";

import {
  Avatar,
  Button,
  ChatIcon,
  ClipboardIcon,
  EmptyState,
  FloppyDiskIcon,
  Heading,
  HistoryIcon,
  IconButton,
  LockIcon,
  MediaIcon,
  Pane,
  RepeatIcon,
  SettingsIcon,
  SideSheet,
  Text,
  VolumeUpIcon,
  majorScale,
  useTheme,
} from "evergreen-ui";
import md5hex from "md5-hex";

import "./app.css";
import Settings, {
  apiKey,
  completionSystemPrompt,
  jsonLocalStorageSignal,
  model,
  nameOrEmail,
  showChat,
  showDownload,
  showImage,
  showTranscribe,
  whisperPrompt,
} from "./Settings";
import SettingToggleButton from "./SettingToggleButton";
import Logo from "./Logo";
import { toExample } from "./examples";
import {
  ChatMessage,
  ImageResponse,
  StreamedResponse,
  sendToChatGpt,
  sendToDallE,
  sendToWhisper,
} from "./open-ai";

export const entries = signal<IDedSignal<Entry>[]>([]);
const recordingState = signal(false);

const autoChat = jsonLocalStorageSignal("AUTO_CHAT", false);
const includeChatHistory = jsonLocalStorageSignal("INCLUDE_CHAT_HISTORY", true);
const speak = jsonLocalStorageSignal("SPEAK", false);

let activeElement: HTMLElement | undefined = undefined;

const resizeObserver = new ResizeObserver(() => {
  activeElement?.scrollIntoView(false);
});

function setActiveElement(el: HTMLElement) {
  if (el === activeElement) {
    return;
  }
  if (activeElement) {
    resizeObserver.unobserve(activeElement);
  }
  activeElement = el;
  resizeObserver.observe(el);
}

let primedSpeechSynthesis = false;

// we need to start an utterance in a user gesture to get speech synthesis to work
// TODO touchstart doesn't seem like a user gesture that works for this
// so priming on record start doesn't work
function primeSpeechSynthesis() {
  if (!primedSpeechSynthesis && speak.value) {
    const u = new SpeechSynthesisUtterance("oh......hello");
    speechSynthesis.speak(u);
    primedSpeechSynthesis = speechSynthesis.speaking;
  }
}
function onChange<T>(s: Signal<T>, cb: (value: T) => void) {
  let started = false;
  s.subscribe((v) => {
    if (started) {
      cb(v);
    }
    started = true;
  });
  started = true;
}

onChange(speak, (v) => {
  primeSpeechSynthesis();
  if (!v) {
    speechSynthesis.cancel();
  }
});

let nextId = 0;
export function getId() {
  return "id:" + nextId++;
}

if (window.location.search === "?example") {
  import("./examples").then(({ loadExamples }) => loadExamples());
}

interface IDedSignal<T> {
  id: string;
  signal: Signal<T>;
}

export interface Entry {
  audio: Blob;
  transcription?: string;
  responses: Signal<IDedSignal<StreamedResponse>[]>;
  imageResponses: Signal<ImageResponse[]>;
}

type ResponseSelection = {
  type: "response";
  entry: Entry;
  response: StreamedResponse;
};
type EntrySelection = {
  type: "entry";
  entry: Entry;
};

type Selection = ResponseSelection | EntrySelection;

function EntryDetails({ selection }: { selection: EntrySelection }) {
  const { entry } = selection;

  return (
    <Pane padding={16}>
      <Pane marginBottom={16}>
        <Heading size={100}>Transcription</Heading>
        <Text size={300}>{entry.transcription}</Text>
      </Pane>
    </Pane>
  );
}

function ResponseDetails({ selection }: { selection: ResponseSelection }) {
  const { response } = selection;

  return (
    <Pane padding={16}>
      <Pane marginBottom={16}>
        <Heading size={100}>Input</Heading>
        <Text size={300}>{response.text}</Text>
      </Pane>
      <Pane marginBottom={16}>
        <Heading size={100}>Response</Heading>
        <Text size={300}>{response.value}</Text>
      </Pane>
      <Pane marginBottom={16}>
        <Heading size={100}>Prompt</Heading>
        <Text size={300}>{response.prompt}</Text>
      </Pane>
      <Pane marginBottom={16}>
        <Heading size={100}>Model</Heading>
        <Text size={300}>{response.model}</Text>
      </Pane>
      <Pane>
        <Button
          iconBefore={ClipboardIcon}
          onClick={() =>
            navigator.clipboard.writeText(
              JSON.stringify(toExample(selection.entry, selection.response))
            )
          }
        >
          Copy example to clipboard
        </Button>
      </Pane>
    </Pane>
  );
}

const SelectionContext = createContext<Signal<Selection | undefined>>(
  undefined as any
);

function Response({
  response,
  entry,
}: {
  response: Signal<StreamedResponse>;
  entry: Entry;
}) {
  const selection = useContext(SelectionContext);

  const { value } = response.value;
  const previousElement = useRef<HTMLElement | null>(null);
  const ref = useCallback((el: HTMLElement | null) => {
    if (el) {
      setActiveElement(el);
    } else if (previousElement.current) {
      resizeObserver.unobserve(previousElement.current);
    }
    previousElement.current = el;
  }, []);

  const onClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      selection.value = {
        type: "response",
        entry,
        response: response.value,
      };
    },
    [selection, entry, response]
  );
  return (
    <p className="response" ref={ref} onClick={onClick}>
      <Pane display="flex">
        <Pane marginRight={majorScale(1)}>
          <Avatar src="https://chat.openai.com/apple-touch-icon.png" />
        </Pane>
        <Pane flex={1}>
          <Text size={300}>{value}</Text>
        </Pane>
      </Pane>
    </p>
  );
}

function Entry({ entry }: { entry: IDedSignal<Entry> }) {
  const { transcription, responses, imageResponses, audio } =
    entry.signal.value;

  const selection = useContext(SelectionContext);

  const onChat = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      if (transcription) {
        addChat(entry);
      }
    },
    [transcription, entry]
  );

  const onTranscribe = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      transcribe(entry);
    },
    [entry]
  );

  const onClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      selection.value = {
        type: "entry",
        entry: entry.signal.value,
      };
    },
    [selection, entry]
  );

  const onGenerateImage = async (e: MouseEvent) => {
    e.preventDefault();
    const result = await sendToDallE({
      apiKey: apiKey.value,
      text: entry.signal.value.transcription!,
    });
    imageResponses.value = [...imageResponses.value, result];
  };

  const url = useMemo(() => URL.createObjectURL(audio), [audio]);
  const actions = [];
  if (showDownload.value) {
    actions.push(
      <a href={url} download={generateFilename(audio)}>
        <IconButton icon={FloppyDiskIcon} marginRight={8} />
      </a>
    );
  }
  if (transcription && showChat.value) {
    actions.push(
      <IconButton icon={ChatIcon} onClick={onChat} marginRight={8} />
    );
  }

  if (showTranscribe.value) {
    actions.push(
      <IconButton icon={RepeatIcon} onClick={onTranscribe} marginRight={8} />
    );
  }

  if (showImage.value) {
    actions.push(
      <IconButton icon={MediaIcon} onClick={onGenerateImage} marginRight={8} />
    );
  }

  const nameOrEmailValue = nameOrEmail.value ?? "Anonymous User";
  const avatar = nameOrEmailValue.includes("@") ? (
    <Avatar
      src={`https://gravatar.com/avatar/${md5hex(
        nameOrEmailValue.toLowerCase()
      )}.jpg`}
    />
  ) : (
    <Avatar name={nameOrEmailValue} />
  );

  return (
    <div className="entry">
      {actions.length > 0 && <div className="actions">{actions}</div>}
      {
        <p className="transcribed">
          <Pane display="flex" onClick={onClick}>
            <Pane marginRight={majorScale(1)}>{avatar}</Pane>
            <Pane flex={1}>
              <Text size={300}>
                {transcription === undefined ? (
                  "…"
                ) : transcription.trim() === "" ? (
                  <span className="no-transcription">
                    (nothing transcribed)
                  </span>
                ) : (
                  transcription
                )}
              </Text>
            </Pane>
          </Pane>
        </p>
      }
      {responses.value.map((response) => {
        return (
          <Response
            key={response.id}
            entry={entry.signal.value}
            response={response.signal}
          />
        );
      })}
      {imageResponses.value.map((imageResponse, i) => {
        return <img key={i} src={imageResponse.data[0].url} alt="" />;
      })}
    </div>
  );
}

function Entries() {
  return (
    <div>
      {entries.value.map((entry) => {
        return <Entry key={entry.id} entry={entry} />;
      })}
    </div>
  );
}

function BigButtonLogoThing() {
  const onTouchStart = useCallback((e: TouchEvent) => {
    e.preventDefault();
    startRecording();
  }, []);
  const onTouchEnd = useCallback((e: TouchEvent) => {
    e.preventDefault();
    endRecording();
  }, []);
  const onClick = useCallback((e: MouseEvent) => {
    e.preventDefault();
    // toggle recording
    if (recordingState.value) {
      endRecording();
    } else {
      startRecording();
    }
  }, []);

  return (
    <div
      id="fixed"
      className={recordingState.value ? "recording" : ""}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      onClick={onClick}
    >
      <Logo />
      <h1>trnscrb</h1>
    </div>
  );
}

function App() {
  const showSettings = useSignal(false);
  const selection = useSignal<Selection | undefined>(undefined);
  const { colors } = useTheme();

  return (
    <SelectionContext.Provider value={selection}>
      <BigButtonLogoThing />
      <div id="main">
        <div id="controls">
          <SettingToggleButton setting={autoChat} icon={ChatIcon} />
          <SettingToggleButton
            setting={includeChatHistory}
            icon={HistoryIcon}
          />
          <SettingToggleButton setting={speak} icon={VolumeUpIcon} />
          <SettingToggleButton setting={showSettings} icon={SettingsIcon} />
        </div>

        {showSettings.value && <Settings />}

        {apiKey.value === "" && !showSettings.value && (
          <EmptyState
            background="dark"
            icon={<LockIcon color={colors.gray500} />}
            iconBgColor={colors.gray200}
            title="You need an OpenAI API key to use trnscrb"
            primaryCta={
              <EmptyState.PrimaryButton
                onClick={() => {
                  showSettings.value = true;
                }}
              >
                Open Settings
              </EmptyState.PrimaryButton>
            }
            anchorCta={
              <EmptyState.LinkButton
                href="https://platform.openai.com/account/api-keys"
                target="_blank"
              >
                Get API Key
              </EmptyState.LinkButton>
            }
          />
        )}

        {selection.value && (
          <SideSheet
            onCloseComplete={() => {
              selection.value = undefined;
            }}
            isShown={true}
          >
            {selection.value.type === "response" ? (
              <ResponseDetails selection={selection.value} />
            ) : selection.value.type === "entry" ? (
              <EntryDetails selection={selection.value} />
            ) : null}
          </SideSheet>
        )}

        <div id="results">
          <Entries />
        </div>
      </div>
    </SelectionContext.Provider>
  );
}

let AudioRecorder: any;

async function start() {
  if (!MediaRecorder.isTypeSupported("audio/webm")) {
    AudioRecorder = (await import("./mpeg-recorder")).default;
  }
  const root = createRoot(document.getElementById("app")!);
  root.render(<App />);
}

start();

export function generateFilename(blob: Blob) {
  return blob.type.startsWith("audio/webm") ? "audio.webm" : "audio.mp3";
}

let blobs: Blob[] = [];
let stream: MediaStream;
let mediaRecorder: MediaRecorder;

async function startRecording() {
  stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  if (AudioRecorder) {
    mediaRecorder = new AudioRecorder(stream);
  } else {
    mediaRecorder = new MediaRecorder(stream, { mimeType: "audio/webm" });
  }

  mediaRecorder.addEventListener("dataavailable", (event) => {
    if (event.data) {
      blobs.push(event.data);
    }
  });
  mediaRecorder.addEventListener("stop", onRecordingFinished);
  mediaRecorder.start();
  recordingState.value = true;
}

function endRecording() {
  recordingState.value = false;
  mediaRecorder.stop();
  stream.getTracks().forEach((track) => track.stop());
}

export function createEntry(audio: Blob): IDedSignal<Entry> {
  return {
    id: getId(),
    signal: signal({
      audio,
      responses: signal([]),
      imageResponses: signal([]),
    }),
  };
}

function addChat(entrySignal: IDedSignal<Entry>) {
  const entry = entrySignal.signal.value;
  const { transcription } = entry;
  if (!transcription) {
    return;
  }

  let history: ChatMessage[] = [];

  if (includeChatHistory.value) {
    const allEntries = entries.value;
    const entriesBefore = allEntries.slice(0, allEntries.indexOf(entrySignal));
    history = entriesToChatMessages(entriesBefore.map((e) => e.signal.value));
  }
  const responses = entry.responses;
  const response = {
    id: getId(),
    signal: sendToChatGpt({
      apiKey: apiKey.value,
      model: model.value,
      systemPrompt: completionSystemPrompt.value,
      text: transcription,
      history,
      onCompletion(text) {
        if (speak.value) {
          let utterance = new SpeechSynthesisUtterance(text);
          speechSynthesis.speak(utterance);
        }
      },
    }),
  };
  responses.value = [...responses.value, response];
}

async function transcribe(entry: IDedSignal<Entry>) {
  const result = await sendToWhisper({
    apiKey: apiKey.value,
    whisperPrompt: whisperPrompt.value,
    blob: entry.signal.value.audio,
  });

  const existing = entry.signal.value;

  entry.signal.value = { ...existing, transcription: result.text };

  // auto chat only applies to new entries, not when re-transcribing
  if (existing.transcription === undefined && autoChat.value) {
    addChat(entry);
  }
}

async function onRecordingFinished() {
  if (!blobs.length) {
    return;
  }

  const blob = new Blob(blobs, { type: mediaRecorder.mimeType });
  blobs = [];

  const entry = createEntry(blob);

  entries.value = [...entries.value, entry];

  await transcribe(entry);
}

function entriesToChatMessages(entries: Entry[]): ChatMessage[] {
  const result: ChatMessage[] = [];
  for (const entry of entries) {
    if (entry.transcription) {
      result.push({ role: "user", content: entry.transcription });
    }
    const responses = entry.responses.value;
    if (responses.length > 0) {
      const lastResponse = responses[responses.length - 1].signal.value;
      if (lastResponse.value) {
        result.push({ role: "assistant", content: lastResponse.value });
      }
    }
  }
  return result;
}

function onReturnKeyPressed(event: KeyboardEvent) {
  if (event.key === "Enter" || event.keyCode === 13) {
    if (recordingState.value) {
      endRecording();
    }
    startRecording();
  }
}

document.addEventListener("keydown", onReturnKeyPressed);

function onEscapeKeyPressed(event: KeyboardEvent) {
  if (event.key === "Escape" || event.keyCode === 27) {
    if (recordingState.value) {
      endRecording();
    }
  }
}

document.addEventListener("keydown", onEscapeKeyPressed);
