import { ChangeEvent, ReactElement, useCallback } from "react";
import { Signal } from "@preact/signals-react";

import {
  Button,
  Checkbox,
  IconButton,
  IconButtonProps,
  IconComponent,
  SettingsIcon,
  Tooltip,
} from "evergreen-ui";

export default function SettingToggle({
  setting,
  icon: Icon,
}: {
  setting: Signal<boolean>;
  icon: IconComponent;
}) {
  const onChange = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      setting.value = !setting.value;
    },
    [setting]
  );
  return (
    <IconButton
      onClick={onChange}
      icon={<Icon color={setting.value ? "dark" : "muted"} />}
      marginRight={8}
    />
  );
}
