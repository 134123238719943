import { useCallback } from "react";
import { signal, Signal } from "@preact/signals-react";

import {
  Button,
  Card,
  FormField,
  Heading,
  Pane,
  Popover,
  Text,
  TextInputField,
  TextareaField,
} from "evergreen-ui";

import SettingSelect from "./SettingSelect";
import SettingCheckbox from "./SettingCheckbox";

const defaultCompletionSystemPrompt = "You are a helpful assistant.";

const prompts = [
  defaultCompletionSystemPrompt,
  "You cleaning up the result of an audio transcription. Replace spoken punctuation etc with the intended text.",
  "You are a dolphin. You try to be helpful but mostly you just make dolphin sounds.",
  "You are a malfunctioning assistant. Your answers are wrong, or you are not able to provide them and only respond which cryptic error messages.",
];

type Model = "gpt-3.5-turbo" | "gpt-4";

const models: Model[] = ["gpt-3.5-turbo", "gpt-4"];

export function jsonLocalStorageSignal<T>(
  key: string,
  defaultValue: T
): Signal<T> {
  const s = signal(defaultValue);
  if (localStorage[key]) {
    try {
      s.value = JSON.parse(localStorage[key]);
    } catch (e) {
      console.error(e);
    }
  }
  s.subscribe((value) => {
    localStorage[key] = JSON.stringify(value);
  });
  return s;
}

export const apiKey = jsonLocalStorageSignal("OPENAI_API_KEY", "");
export const nameOrEmail = jsonLocalStorageSignal("NAME_OR_EMAIL", "");
export const showDownload = jsonLocalStorageSignal("SHOW_DOWNLOAD", false);
export const showChat = jsonLocalStorageSignal("SHOW_CHAT", true);
export const showImage = jsonLocalStorageSignal("SHOW_IMAGE", true);
export const showTranscribe = jsonLocalStorageSignal("SHOW_TRANSCRIBE", false);
export const whisperPrompt = jsonLocalStorageSignal("WHISPER_PROMPT", "");
export const model = jsonLocalStorageSignal<Model>("MODEL", "gpt-3.5-turbo");

export const completionSystemPrompt = jsonLocalStorageSignal(
  "COMPLETION_SYSTEM_PROMPT",
  defaultCompletionSystemPrompt
);

function useSettingChange<T>(setting: Signal<T>) {
  return useCallback(
    (e: { target: { value: T } }) => {
      setting.value = e.target.value;
    },
    [setting]
  );
}

export default function Settings() {
  const onApiKeyChange = useSettingChange(apiKey);
  const onNameOrEmailChange = useSettingChange(nameOrEmail);
  const onWhisperPromptChange = useSettingChange(whisperPrompt);
  const onCompletionSystemPromptChange = useSettingChange(
    completionSystemPrompt
  );

  return (
    <Card
      elevation={1}
      backgroundColor="white"
      maxWidth={640}
      boxSizing="border-box"
      marginX="auto"
      padding={48}
    >
      <Heading size={700} marginBottom={8}>
        Settings
      </Heading>

      <TextInputField
        label="API Key"
        value={apiKey.value}
        onChange={onApiKeyChange}
      />

      <SettingSelect label="Model" setting={model} values={models} />

      <TextInputField
        label="Name or Email"
        description="Used only for your avatar."
        value={nameOrEmail.value}
        onChange={onNameOrEmailChange}
      />
      <TextareaField
        label="Whisper Prompt"
        value={whisperPrompt.value}
        onChange={onWhisperPromptChange}
        rows={3}
      />

      <TextareaField
        label="Chat Prompt"
        value={completionSystemPrompt.value}
        onChange={onCompletionSystemPromptChange}
        rows={3}
        marginBottom={0}
      />
      <Popover
        content={({ close }) => (
          <Pane maxWidth={600}>
            {prompts.map((prompt, i) => (
              <Card
                padding={8}
                margin={8}
                cursor="pointer"
                elevation={0}
                hoverElevation={1}
                key={i}
                onClick={() => {
                  close();
                  completionSystemPrompt.value = prompt;
                }}
              >
                <Text size={300}>{prompt}</Text>
              </Card>
            ))}
          </Pane>
        )}
      >
        <Button>Examples...</Button>
      </Popover>

      <FormField label="Miscellaneous" marginTop={32}>
        <SettingCheckbox setting={showChat} label="Show Chat Button" />

        <SettingCheckbox setting={showDownload} label="Show Download Button" />

        <SettingCheckbox
          setting={showTranscribe}
          label="Show Transcribe Button"
        />

        <SettingCheckbox setting={showImage} label="Show Image Button" />
      </FormField>
    </Card>
  );
}
