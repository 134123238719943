export default function Logo() {
  return (
    <svg
      id="logo"
      viewBox="5 0 65 85"
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
    >
      <path
        d="M 30 20 C 10 50, 10 80, 30 80 Q 50 80, 50 60 Q 50 50, 40 50 C 30 50, 30 60, 30 70 Q 30 80, 50 80 C 70 80, 70 50, 50 20 Q 30 -10, 10 20 Q 30 50, 50 20"
        stroke="black"
        strokeWidth="3"
        fill="transparent"
      />
      {/* <rect
        x="10"
        y="5"
        width="55"
        height="75"
        stroke="black"
        strokeWidth="3"
        fill="transparent"
      /> */}
    </svg>
  );
}
