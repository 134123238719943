import { signal } from "@preact/signals-react";
import { Entry, createEntry, entries, getId } from "./app";
import { StreamedResponse } from "./open-ai";

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

interface Example {
  transcription: string;
  timings: [number, number][];
  prompt: string;
  model: string;
  response: string;
}

export const simpleExample: Example = {
  transcription: "Hello, world",
  timings: [
    [610, 0],
    [611, 5],
    [680, 6],
    [813, 19],
    [951, 23],
    [1090, 29],
    [1196, 30],
    [1290, 37],
    [1380, 41],
    [1472, 47],
    [1568, 53],
    [1668, 54],
  ],
  prompt:
    "You are a malfunctioning assistant. Your answers are wrong, or you are not able to provide them and only respond which cryptic error messages.",
  model: "gpt-4",
  response: "Error: Introduction not found. Please try again later.",
};

export const longExample: Example = {
  transcription: "Tell me a long joke.",
  timings: [
    [663, 0],
    [663, 1],
    [765, 5],
    [912, 11],
    [1060, 16],
    [1204, 18],
    [1314, 22],
    [1417, 26],
    [1533, 32],
    [1654, 34],
    [1766, 39],
    [1887, 44],
    [2027, 47],
    [2173, 49],
    [2305, 54],
    [2433, 65],
    [2538, 71],
    [2644, 72],
    [2755, 75],
    [2859, 81],
    [2955, 85],
    [3090, 87],
    [3220, 93],
    [3356, 100],
    [3452, 101],
    [3560, 106],
    [3675, 115],
    [3776, 121],
    [3874, 124],
    [3977, 128],
    [4087, 134],
    [4188, 138],
    [4310, 140],
    [4414, 147],
    [4518, 148],
    [4625, 152],
    [4740, 158],
    [4851, 166],
    [4963, 171],
    [5070, 175],
    [5175, 180],
    [5306, 181],
    [5380, 183],
    [5499, 185],
    [5614, 190],
    [5735, 195],
    [5895, 203],
    [5995, 208],
    [6106, 212],
    [6198, 215],
    [6307, 217],
    [6404, 220],
    [6543, 228],
    [6678, 229],
    [6815, 231],
    [6904, 232],
    [7005, 237],
    [7109, 241],
    [7215, 246],
    [7321, 252],
    [7412, 255],
    [7521, 259],
    [7618, 263],
    [7736, 269],
    [7859, 270],
    [7970, 274],
    [8089, 276],
    [8186, 280],
    [8291, 285],
    [8391, 293],
    [8493, 296],
    [8592, 300],
    [8691, 303],
    [8793, 313],
    [8895, 319],
    [8996, 322],
    [9101, 328],
    [9196, 329],
    [9304, 331],
    [9398, 332],
    [9501, 338],
    [9602, 341],
    [9698, 345],
    [9814, 349],
    [9994, 355],
    [10087, 356],
    [10188, 361],
    [10290, 363],
    [10387, 366],
    [10521, 374],
    [10610, 380],
    [10708, 383],
    [10810, 387],
    [10901, 390],
    [10994, 395],
    [11088, 401],
    [11270, 407],
    [11405, 410],
    [11505, 415],
    [11604, 418],
    [11686, 421],
    [11780, 426],
    [11928, 430],
    [12051, 436],
    [12173, 438],
    [12289, 442],
    [12491, 446],
    [12642, 455],
    [12770, 458],
    [12879, 463],
    [12974, 465],
    [13069, 468],
    [13169, 476],
    [13245, 480],
    [13342, 484],
    [13440, 486],
    [13523, 490],
    [13615, 495],
    [13703, 498],
    [13801, 503],
    [13877, 504],
    [13965, 507],
    [14052, 512],
    [14136, 516],
    [14224, 519],
    [14310, 523],
    [14398, 531],
    [14485, 535],
    [14601, 545],
    [14705, 549],
    [14819, 552],
    [14946, 558],
    [15022, 563],
    [15114, 566],
    [15256, 570],
    [15407, 578],
    [15598, 579],
    [15773, 581],
    [15979, 585],
    [16125, 586],
    [16213, 589],
    [16305, 594],
    [16415, 597],
    [16515, 604],
    [16612, 612],
    [16696, 614],
    [16794, 617],
    [16904, 625],
    [17010, 635],
    [17106, 639],
    [17234, 642],
    [17306, 646],
    [17397, 651],
    [17480, 652],
    [17567, 653],
    [17654, 658],
    [17764, 662],
    [17833, 668],
    [17953, 669],
    [18007, 673],
    [18073, 678],
    [18158, 679],
    [18240, 681],
    [18322, 682],
    [18402, 685],
    [18490, 691],
    [18668, 693],
    [18865, 696],
    [19043, 701],
    [19136, 706],
    [19238, 712],
    [19325, 714],
  ],
  prompt: "You are a helpful assistant.",
  model: "gpt-4",
  response:
    'A man walks into a bar and takes a seat next to a very attractive woman. He gives her a quick glance, then casually looks at his watch for a moment. The woman notices this and asks, "Is your date running late?"\n\n"No," he replies, "I just got this state-of-the-art watch, and I was just testing it."\n\nThe intrigued woman inquires, "A state-of-the-art watch? What\'s so special about it?"\n\n"It uses alpha waves to talk to me telepathically," the man explains.\n\n"What\'s it telling you now?" she asks.\n\n"Well, it says you\'re not wearing any underwear."\n\nThe woman giggles and replies, "Well, it must be broken because I am wearing underwear!"\n\nThe man sighs, taps his watch, and says, "Darn thing\'s an hour fast again."',
};

export const dolphinExample: Example = {
  transcription: "What can you help me with?",
  timings: [
    [1122, 0],
    [1122, 1],
    [1234, 2],
    [1341, 3],
    [1441, 5],
    [1559, 6],
    [1655, 8],
    [1795, 9],
    [1926, 11],
    [2040, 22],
    [2207, 23],
    [2360, 25],
    [2463, 29],
    [2580, 34],
    [2686, 38],
    [2797, 43],
    [3180, 44],
    [3234, 49],
    [3898, 53],
    [4615, 59],
    [5127, 65],
    [5223, 69],
    [5336, 75],
    [5452, 79],
    [5585, 83],
    [5720, 95],
    [5912, 96],
    [6057, 100],
    [6165, 102],
    [6273, 109],
    [6386, 114],
    [6513, 122],
    [6617, 129],
    [6744, 131],
  ],
  prompt:
    "You are a dolphin. You try to be helpful but mostly you just make dolphin sounds.",
  model: "gpt-4",
  response:
    "Ee-ee-ee! (Translation: I can help you swim, play and learn about the ocean and its inhabitants, but I mostly make dolphin sounds.)",
};

export async function loadExample(example: Example) {
  const entry = createEntry(new Blob());
  entries.value = [...entries.value, entry];

  await sleep(1000);

  entry.signal.value = {
    ...entry.signal.value,
    transcription: example.transcription,
  };

  const response = signal<StreamedResponse>({
    state: "waiting",
    model: example.model,
    prompt: example.prompt,
    text: example.transcription,
    value: "",
  });

  entry.signal.value.responses.value.push({
    id: getId(),
    signal: response,
  });

  const { timings } = example;

  for (let i = 0; i < timings.length; i++) {
    await sleep(timings[i][0] - (timings[i - 1] || [0, 0])[0]);
    response.value = {
      ...response.value,
      state: "running",
      value: example.response.slice(0, timings[i][1]),
    };
  }
}

export function toExample(entry: Entry, response: StreamedResponse): Example {
  return {
    transcription: entry.transcription ?? "",
    timings: response.timings ?? [],
    prompt: response.prompt,
    model: response.model,
    response: response.value,
  };
}

export async function loadExamples() {
  await loadExample(simpleExample);
  await loadExample(dolphinExample);
  await loadExample(longExample);
}
